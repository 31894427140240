import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

const LatestBlogPost = () => {
  const {
    posts: { nodes },
  } = useStaticQuery<Queries.LatestBlogPostQuery>(graphql`
    query LatestBlogPost {
      posts: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {fileAbsolutePath: {regex: "/(resources)/"  }},
        limit: 1
      ) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  `)
  const { fields, frontmatter } = nodes[0]!

  const { slug } = fields!
  const { title } = frontmatter!
  return (
    <span className="block">
      Latest blog post: <br />
      <Link to={`/blog/post${slug}`} className="underline">
        {title} →
      </Link>
    </span>
  )
}

export default LatestBlogPost
