import * as React from 'react'
import Hero from '../components/LandingPage/Hero'
import Terminal from '../components/LandingPage/Terminal'
import Layout from '../components/Layout'
import SEO from "../components/SEO";

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <Terminal />
    </Layout>
  )
}

export default IndexPage

export const Head = () => {
    return (
        <SEO />
    )
}
