import React, { FC, PropsWithChildren } from 'react'

const SingleBadge: FC<PropsWithChildren> = ({ children }) => (
  <span className="my-2 px-4 py-2 bg-primary text-gray-300 font-primary transition hover:bg-gray-800 rounded-3xl md:mx-2 first:ml-0 whitespace-nowrap">
    {children}
  </span>
)

const badges = [
  '👨‍💻 Front-end engineer',
  '🔓 OSS & civic tech enthusiast',
  '🎟️ Events organizer',
]

const Badges = () => (
  <h3 className="mt-3 flex flex-wrap">
    {badges.map((badge) => (
      <SingleBadge key={badge}>{badge}</SingleBadge>
    ))}
  </h3>
)

export default Badges
