import React from 'react'
import socials from '../../data/socials'

const SocialIcons = () => (
  <div className="flex items-center">
    {socials.map(({ icon: Icon, url }) => (
      <a href={url} className="block mx-4 first:ml-0" key={url}>
        <Icon className="text-xl fill-current" />
      </a>
    ))}
  </div>
)

export default SocialIcons
