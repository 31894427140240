import React from 'react'
import useHotKeyRedirects from './useHotKeyRedirects'

const links = [
  ['About me', '/about'],
  ['Experience', '/experience'],
  // ['Events', '/events'],
  ['Blog', '/blog'],
  // ['Resources', '/resources'],
  // ['Work with me', '/work-with-me'],
  ['Contact me', '/contact'],
]

const Terminal = () => {
  useHotKeyRedirects()
  return (
    <div className=" mt-20 relative">
      <div className="w-11/12 md:w-4/5 2xl:w-1/2 mx-auto bg-primary text-white -mt-10 p-5 rounded-xl shadow-xl font-primary lg:flex gap-x-2">
        <div>
          <span className="text-accent">micorix@micorix</span>:~${' '}
          <span className="inline lg:hidden">man micorix</span>
        </div>
        <div>
          <span className="hidden lg:block">man micorix</span>
          <p className="mt-3 leading-8">
            <strong>Short introduction</strong>
            <br />I{"'"}m 20 y/o front-end engineer based in Warsaw, Poland. I
            craft web apps that help people and websites that convey meaningful
            stories.
            <br />
            <br />
            <ul className="hidden lg:flex">
              {links.map(([label, link]) => {
                const [firstChar, ...rest] = label
                return (
                  <li className="mx-4 first:ml-0 whitespace-nowrap" key={link}>
                    <a href={link}>
                      <span className="underline ">[{firstChar}]</span>
                      {rest}
                    </a>
                  </li>
                )
              })}
            </ul>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Terminal
