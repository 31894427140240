import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import LatestBlogPost from './LatestBlogPost'
import SocialIcons from './SocialIcons'
import Badges from './Badges'

const Hero = () => {
  return (
    <div className="pt-32 lg:pt-52">
      <div className="w-4/5 mx-auto">
        <div className="xl:flex items-center">
          <StaticImage
            src="../../images/michal.jpg"
            alt=""
            className="rounded-full w-40 lg:w-72 shadow-2xl"
          />
          <div className="mt-10 xl:mt-0 xl:ml-10">
            <span className="text-sm">Hi! I{"'"}m</span>
            <h1 className="text-4xl md:text-6xl font-primary text-gray-300">
              Michał Oręziak&nbsp;
              <span className="block lg:inline">
                <span className="text-sm">a.k.a.</span>&nbsp;
                <span className="text-4xl md:text-6xl font-secondary text-accent">
                  micorix
                </span>
              </span>
            </h1>
            <Badges />
            <h3 className="mt-5 lg:mt-10">I currently work @ <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://qed.ai"
            >QED.ai</a></h3>
            <div className="mt-8 grid lg:grid-cols-2 gap-y-10">
              <SocialIcons />
              <LatestBlogPost />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
