import useEventListener from '@use-it/event-listener'
import { navigate } from 'gatsby'

const getPath = (key: string) => {
  switch (key) {
    case 'a':
      return '/about'
    case 'e':
      return '/experience'
    case 'b':
      return '/blog'
    case 'c':
      return '/contact'
    default:
      return null
  }
}

const useHotKeyRedirects = () => {
  useEventListener('keydown', (e) => {
    const path = getPath((e as KeyboardEvent).key)
    if (path) {
      navigate(path)
    }
  })
}

export default useHotKeyRedirects
